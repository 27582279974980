// ============================================================================
// TreeStructureService
// -----------------------
// TreeStructure module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/TreeStructureApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        vendor: {
            treeStructure: {
                create: NotImplementedYet,
                read: API.vendor.treeStructure.read,
                update: NotImplementedYet,
                delete: NotImplementedYet
            },
            treeStructures: {
                create: NotImplementedYet,
                read: API.vendor.treeStructures.read,
                update: NotImplementedYet,
                delete: NotImplementedYet
            }
        }
    },
    service: {
        vendor: {
            treeStructure: {
                create: NotImplementedYet,
                read: function (vendorId, treeStructureId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ vendorId, treeStructureId })
                    return Private.requests.vendor.treeStructure.read(vendorId, treeStructureId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                replace: NotImplementedYet,
                delete: NotImplementedYet,
            },
            treeStructures: {
                create: NotImplementedYet,
                read: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ vendorId })
                    return Private.requests.vendor.treeStructures.read(vendorId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                update: NotImplementedYet,
                delete: NotImplementedYet
            }
        }
    },
    store: {}
}
// -------
// Exports
// -------
export default {
    createTreeStructure: Private.service.vendor.treeStructure.create,
    getTreeStructure: Private.service.vendor.treeStructure.read,
    updateTreeStructure: Private.service.vendor.treeStructure.update,
    deleteTreeStructure: Private.service.vendor.treeStructure.delete,

    createTreeStructures: Private.service.vendor.treeStructures.create,
    getTreeStructures: Private.service.vendor.treeStructures.read,
    updateTreeStructures: Private.service.vendor.treeStructures.update,
    deleteTreeStructures: Private.service.vendor.treeStructures.delete
}