// ============================================================================
// TreeStructureApi
// -------------------
// TreeStructure api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
    vendor: {
        treeStructure:  {
            create: NotImplementedYet,
            read: function(vendorId, treeStructureId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ vendorId: vendorId, treeStructureId: treeStructureId })
                return Backend.GET(URLS.api.vendors.treeStructures.uri, [vendorId, treeStructureId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        },
        treeStructures: {
            create: NotImplementedYet,
            read: function(vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ vendorId: vendorId })
                return Backend.GET(URLS.api.vendors.treeStructures.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    }
}