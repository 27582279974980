export default {
	props: {
		simpleButtonDisplay: {
			type: Boolean,
			required: false,
			default: true
		},
		listTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		}
	}
}
