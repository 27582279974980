// ============================================================================
// DocumentsCounterService
// -----------------------
// Documents counter module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/DocumentsCounterApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			node: {
				counters: {
					create: NotImplementedYet,
					update: NotImplementedYet,
					read: function (accountingFirmId, vendorId, nodeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, nodeId: nodeId })
						return API.vendor.node.counters.read(accountingFirmId, vendorId, nodeId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
					},
					delete: function (accountingFirmId, vendorId, nodeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, nodeId: nodeId })
						return API.vendor.node.counters.delete(accountingFirmId, vendorId, nodeId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
					}
				}
			},
			nodes: {
				counters: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
						return API.vendor.nodes.counters.delete(accountingFirmId, vendorId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
					}
				}
			},
			counters: {
				create: NotImplementedYet,
				read: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
					return API.vendor.counters.read(accountingFirmId, vendorId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		},
		vendors: {
			counters: {
				create: NotImplementedYet,
				read: function (accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return API.vendors.counters.read(accountingFirmId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	service: {
		vendor: {
			counters: {
				create: NotImplementedYet,
				read: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
					return Private.requests.vendor.counters.read(accountingFirmId, vendorId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			node: {
				counters: {
					create: NotImplementedYet,
					read: function (accountingFirmId, vendorId, node) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, node: node })
						return Private.requests.vendor.node.counters.read(accountingFirmId, vendorId, node.id).then(data => data.counter)
					},
					load: function (accountingFirmId, vendorId, node) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, node: node })
						return Private.service.vendor.node.counters.read(accountingFirmId, vendorId, node).then(counter => {
							Private.store.vendor.node.counters.update(node, counter)
							return counter
						})
					},
					update: NotImplementedYet,
					delete: function (accountingFirmId, vendorId, node) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, node: node })
						return Private.requests.vendor.node.counters.delete(accountingFirmId, vendorId, node.id).then(() => {
							Private.store.vendor.node.counters.update(node, 0)
						})
					}
				}
			},
			nodes: {
				counters: {
					create: NotImplementedYet,
					read: NotImplementedYet /*function(accountingFirmId, vendorId, nodes) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, nodes: nodes })
						return Private.requests.vendor.nodes.counters.read(accountingFirmId, vendorId, node.id).then(data => data.counter)
					}*/,
					load: NotImplementedYet,
					update: NotImplementedYet,
					delete: function (accountingFirmId, vendorId, nodes) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, nodes: nodes })
						const params = {}
						if (Array.isArray(nodes)) {
							const foldersId = nodes.filter(node => node.is_folder).map(folder => folder.id)
							if (foldersId.length > 0) {
								params.folder_id = foldersId
							}
							const documentsId = nodes.filter(node => node.is_document).map(document => document.id)
							if (documentsId.length > 0) {
								params.document_id = documentsId
							}
						}
						return Private.requests.vendor.nodes.counters.delete(accountingFirmId, vendorId, params).then(() => {
							nodes.forEach(node => Private.store.vendor.node.counters.update(node, 0))
						})
					}
				}
			}
		},
		vendors: {
			counters: {
				create: NotImplementedYet,
				read: function (accountingFirmId) {
					RequiredArguments({ accountingFirmId: accountingFirmId })
					return Private.requests.vendors.counters.read(accountingFirmId)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	store: {
		vendor: {
			node: {
				counters: {
					update: function (node, counter) {
						RequiredArguments({ node: node })
						return store.dispatch('documents/setNodeCounter', { nodeId: node.id, counter: counter })
					}
				}
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	createCounters: Private.service.vendors.counters.create,
	getCounters: Private.service.vendors.counters.read,
	updateCounters: Private.service.vendors.counters.update,
	deleteCounters: Private.service.vendors.counters.delete,

	getVendorCounters: Private.service.vendor.counters.read,

	createNodeCounter: Private.service.vendor.node.counters.create,
	getNodeCounter: Private.service.vendor.node.counters.read,
	loadNodeCounter: Private.service.vendor.node.counters.load,
	updateNodeCounter: Private.service.vendor.node.counters.update,
	deleteNodeCounter: Private.service.vendor.node.counters.delete,

	createNodesCounter: Private.service.vendor.nodes.counters.create,
	getNodesCounter: Private.service.vendor.nodes.counters.read,
	loadNodesCounter: Private.service.vendor.nodes.counters.load,
	updateNodesCounter: Private.service.vendor.nodes.counters.update,
	deleteNodesCounter: Private.service.vendor.nodes.counters.delete
}
