import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

/**
 * @api GET /api/vendors/{vendorId}
 *
 * @param {Number} vendorId
 * @param {Object} params
 * 
 * @return {Promise}
 */
const getCompany = (vendorId, params = {}) => {
	const url = `vendors/${vendorId}`
	return axios.get(url, {params})
}

/**
 * @api GET /api/vendors/{vendorId}/users
 *
 * @param {Number} vendorId
 * 
 * @return {Promise}
 */
const getUsers = vendorId => {
	const url = `vendors/${vendorId}/users`
	return axios.get(url)
}

export default {
	getCompany: getCompany,
	getUsers: getUsers
}
