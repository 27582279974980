// ============================================================================
// SettingsService
// ---------------
// Settings module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/SettingsApi'

// -------
// Exports
// -------
export default {
	getCompany: function (vendorId) {
		return API.getCompany(vendorId, { with: ['naf_code', 'details']}).then(res => res.data.data)
	},
	getUsers: function (vendorId) {
		return API.getUsers(vendorId).then(res => res.data.data)
	}
}
