import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

export default {
	vendor: {
		node: {
			counters: {
				create: NotImplementedYet,
				update: NotImplementedYet,
				/* Explication: tentative d'utilisation de la Fetch API au lieu d'Axios
				   afin de préciser l'importance faible de la requête dans le but de ne
				   plus pénaliser les requêtes de navigation dans la GED */
				// eslint-disable-next-line no-unused-vars
				read: function (accountingFirmId, vendorId, nodeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, nodeId: nodeId })
					return Backend.fetchApi.GET(
						URLS.api.accountingFirms.vendors.nodes.counters.uri,
						[accountingFirmId, vendorId, nodeId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests,
						Backend.fetchApi.priority.low
					)
				},
				delete: function (accountingFirmId, vendorId, nodeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
					return Backend.DELETE(
						URLS.api.accountingFirms.vendors.nodes.counters.uri,
						[accountingFirmId, vendorId, nodeId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				}
			}
		},
		nodes: {
			counters: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
					return Backend.DELETE(
						URLS.api.accountingFirms.vendors.nodes.counters.uri,
						[accountingFirmId, vendorId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				}
			}
		},
		counters: {
			create: NotImplementedYet,
			read: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
				return Backend.fetchApi.GET(
					URLS.api.accountingFirms.vendors.counters.uri,
					[accountingFirmId, vendorId],
					params,
					doCancelPreviousRequest,
					doCancelAllOtherRequests,
					Backend.fetchApi.priority.low
				)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	},
	vendors: {
		counters: {
			create: NotImplementedYet,
			read: function (accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ accountingFirmId: accountingFirmId })
				return Backend.GET(URLS.api.accountingFirms.vendors.counters.uri, [accountingFirmId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	}
}
