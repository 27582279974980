<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout v-if="companies" fill-height column>
		<v-flex v-show="!mini" shrink px-3>
			<v-text-field v-model="companiesSearch" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</v-flex>
		<v-flex v-show="!mini" shrink>
			<v-layout row justify-space-around>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="showFilters = !showFilters">
								<v-icon :color="filtersSet ? 'error' : 'primary'">filter_list</v-icon>
							</v-btn>
						</template>
						<span v-t="'actions.filter'" />
					</v-tooltip>
				</v-flex>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByName()">
								<v-badge color overlap :top="sortByName === true" :bottom="sortByName === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByName === null ? '' : sortByName ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">sort_by_alpha</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{ sortByName === null ? $t('documents.sort_by_name_asc') : sortByName ? $t('documents.sort_by_name_desc') : $t('documents.do_not_sort') }}
						</span>
					</v-tooltip>
				</v-flex>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByDate()">
								<v-badge color overlap :top="sortByDate === true" :bottom="sortByDate === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByDate === null ? '' : sortByDate ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">update</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{ sortByDate === null ? $t('documents.sort_by_date_asc') : sortByDate ? $t('documents.sort_by_date_desc') : $t('documents.do_not_sort') }}
						</span>
					</v-tooltip>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-divider v-if="!mini && showFilters" />
		<v-flex v-if="!mini && showFilters" shrink>
			<v-layout column pa-3>
				<v-flex v-t="'documents.filters.intro'" align-center />
				<v-checkbox
					v-model="onlyFavorites"
					color="primary"
					:disabled="(!favoritesCompanies || favoritesCompanies.length === 0) && !onlyFavorites"
					hide-details
					:label="$t('documents.filters.favorites')"
				/>
				<v-checkbox
					v-model="onlyWithNotification"
					color="primary"
					hide-details
					:label="$t('documents.filters.notifications')"
				/>
			</v-layout>
		</v-flex>
		<v-divider v-if="!mini && !onlyFavorites" />
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex grow scroll-y style="height: 0">
			<v-layout fill-height column>
				<v-flex shrink>
					<v-subheader v-t="'company.companies'"></v-subheader>
				</v-flex>
				<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
				<v-flex grow scroll-y style="height: 0">
					<w-virtual-list :dense="mini" :items="allCompanies" @update:visible-items="onUpdateVisibleItems">
						<template v-slot:default="{ item: company }">
							<v-hover>
								<template v-slot:default="{ hover }">
									<v-list-tile
										:key="company.id"
										avatar
										:value="company.id === selectedCompany?.id"
										active-class="primary lighten-5 primary--text font-weight-bold"
										@click="selectCompany(company)"
									>
										<v-list-tile-avatar>
											<CompanyAvatar v-if="company" :value="company">
												<template v-slot:topLeftBadge="slotProps">
													<CounterBadge is-company :value="company" v-bind="slotProps" @update="updateCounter(company)" />
												</template>
												<template v-slot:topRightBadge="slotProps">
													<FavoriteBadge :value="company" v-bind="slotProps" />
												</template>
											</CompanyAvatar>
										</v-list-tile-avatar>
										<v-list-tile-content v-show="!mini">
											<v-list-tile-title>
												<v-flex v-show="hover" text-truncate>
													<span v-if="company.nameHtml" v-html="company.nameHtml"></span>
													<span v-else v-text="company.fullname"></span>
												</v-flex>
												<v-tooltip v-show="!hover" bottom>
													<template v-slot:activator="{ on }">
														<v-flex v-if="company.nameHtml" v-on="on" v-html="company.nameHtml" />
														<v-flex v-else v-on="on" v-text="company.fullname" />
													</template>
													<v-flex text-truncate v-text="company.client_code ? `${company.company} (${company.client_code})` : company.company" />
												</v-tooltip>
											</v-list-tile-title>
										</v-list-tile-content>
										<v-list-tile-action v-show="!mini && hover">
											<v-flex shrink>
												<v-tooltip top>
													<template v-slot:activator="{ on }">
														<v-btn icon v-on="on" @click.stop="toggleFavorite(company)">
															<v-icon color="yellow">{{ company.favorite ? 'star' : 'star_border' }}</v-icon>
														</v-btn>
													</template>
													<span>{{ company.favorite ? $t('documents.favorites.remove') : $t('documents.favorites.add') }}</span>
												</v-tooltip>
											</v-flex>
										</v-list-tile-action>
										<v-list-tile-action v-show="!mini && hover">
											<v-flex shrink>
												<v-tooltip top>
													<template v-slot:activator="{ on }">
														<v-btn icon v-on="on" @click.stop="gotoSettings(company)">
															<v-icon color="primary">settings</v-icon>
														</v-btn>
													</template>
													<span>{{
														$t('settings.title_company', { company: company.client_code ? `${company.company} (${company.client_code})` : company.company })
													}}</span>
												</v-tooltip>
											</v-flex>
										</v-list-tile-action>
									</v-list-tile>
								</template>
							</v-hover>
						</template>
					</w-virtual-list>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DocumentsCounterModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsCounterModuleGuard'
import CustomersManagerService from '@/services/CustomersManager/CustomersManagerService'

export default {
	name: 'CompaniesList',
	components: {
		CompanyAvatar: () => ({
			component: import('@/components/Documents/CompanyAvatar')
		}),
		CounterBadge: () => ({
			component: import('@/components/Documents/Counter/CounterBadge')
		}),
		FavoriteBadge: () => ({
			component: import('@/components/Documents/FavoriteBadge')
		})
	},
	mixins: [DocumentsCounterModuleGuard],
	data: function () {
		return {
			loading: true,
			mini: false,
			companiesSearch: '',
			showFilters: false,
			onlyFavorites: null,
			onlyWithNotification: null,
			orderedAlphaAsc: null,
			sidebar: false,
			sortByName: localStorage.getItem('ecmclsortbyname') ? localStorage.getItem('ecmclsortbyname') === 'true' : null,
			sortByDate: localStorage.getItem('ecmclsortbydate'),
			counters: {},
			visibleItems: [],
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected
		}),
		filtersSet: function () {
			let result = false
			if (this.onlyFavorites || this.onlyWithNotification) {
				result = true
			}
			return result
		},
		showFavorites: function () {
			let result = true
			if (this.mini || (this.companiesSearch && this.companiesSearch.trim().length > 0) || (this.favoritesCompanies && this.favoritesCompanies.length === 0)) {
				result = false
			}
			return result
		},
		companiesWithNotifications: function () {
			return this.companies.map(company => {
				company.notifications = this.counters[company.id] ?? 0
				return company
			})
		},
		sortedCompanies: function () {
			return this.doSort(this.companiesWithNotifications)
		},
		favoritesCompanies: function () {
			let result = []
			if (this.sortedCompanies) {
				result = this.sortedCompanies.filter(company => company.favorite)
				if (this.onlyWithNotification && this.hasSomeNotifications) {
					result = result.filter(company => company.notifications > 0)
				}
			}
			return result
		},
		hasSomeNotifications: function () {
			return this.sortedCompanies.some(company => company.notifications > 0)
		},
		allCompanies: function () {
			return this.doSort([...this.filteredCompanies].sort(a => (a.favorite ? -1 : 1)))
		},
		allCompaniesCounterLoaded: function () {
			return this.companiesWithNotifications.every(company => !!company.loadedCounter)
		},
		filteredCompanies: function () {
			let result = this.sortedCompanies
			if (this.onlyFavorites) {
				result = result.filter(company => company.favorite)
			}
			if (this.onlyWithNotification) {
				result = result.filter(company => company.notifications && company.notifications > 0)
			}
			result.forEach(company => (company.fullname = company.client_code ? company.company + ' (' + company.client_code + ')' : company.company))
			result = [
				...result
					.filter(
						company =>
							!this.companiesSearch ||
							!this.companiesSearch.trim().length > 0 ||
							company.fullname.trim().toLowerCase().indexOf(this.companiesSearch.trim().toLowerCase()) !== -1
					)
					.map(company => {
						company.nameHtml = this.$highlightMatching(company.fullname, this.companiesSearch)
						return company
					})
			]
			return result
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		favoritesCompanies: {
			deep: true,
			handler: function (value, oldValue) {
				if (value?.length !== oldValue?.length) {
					this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
				}
			},
			immediate: true
		},
		showFilters: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		sidebar: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		accountingFirmId: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.refreshCompaniesList()
				}
			}
		},
		async onlyWithNotification (newValue) {
			if (! newValue) {
				localStorage.removeItem('onlyWithNotification')
				return;
			}
			localStorage.setItem('onlyWithNotification', newValue)
			await this.loadCounters(this.companies);
		},
		onlyFavorites (newValue) {
			if (! newValue) {
				localStorage.removeItem('onlyFavorites')
				return;
			}
			localStorage.setItem('onlyFavorites', newValue)
		},
		visibleItems () {
			this.loadCounters(this.visibleItems);
		}
	},
	created: function () {
		this.onlyFavorites = localStorage.getItem('onlyFavorites') === 'true';
		this.onlyWithNotification = localStorage.getItem('onlyWithNotification') === 'true';
	},
	mounted: function () {
		this.refreshCompaniesList()
	},
	methods: {
		...mapActions({
			resetFilter: 'documents/resetFilter',
		}),
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized },
				{ event: this.appEvents.SHOW_SIDEBAR, action: this.onShowSideBar },
				{ event: this.appEvents.HIDE_SIDEBAR, action: this.onHideSideBar }
			]
		},
		fetchCounter: async function(accountingFirmId, vendorId) {
			try {
				const response = await this.service.getVendorCounters(accountingFirmId, vendorId);
				if (response !== null) {
					return { id: vendorId, response };
				}
				return null;
			} catch (error) {
				return null;
			}
		},
		batchLoadCounters: async function (companies) {
			const promises = companies
					.filter(({ loadedCounter }) => !loadedCounter)
					.map((company) => this.fetchCounter(this.accountingFirmId, company.id));

			const results = await Promise.allSettled(promises)
			results.forEach(result => {
				if (result.status === 'fulfilled' && result.value !== null) {
					const { id, response } = result.value;
					const company = this.companies.find(c => c.id === id);
					if (! company) {
						return;
					}
					company.loadedCounter = true;
					this.counters = { ...this.counters, ...response };
				}
			});
		},
		loadCounters: async function (companies) {
			if (this.allCompaniesCounterLoaded) {
				return;
			}
			this.loading = true
			if (! this.accountingFirmId) {
				this.loading = false
				return;
			}
			await this.batchLoadCounters(companies);
			this.loading = false
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		onShowSideBar: function () {
			this.sidebar = true
		},
		onHideSideBar: function () {
			this.sidebar = false
		},
		selectCompany: function (vendor) {
			localStorage.setItem('lastSelectedCompanyId', vendor.id)
			this.resetFilter()
			this.appService.goTo({
				params: {
					accounting_firm_id: this.accountingFirmId,
					vendor_id: vendor.id
				}
			})
		},
		doSortByName: function () {
			if (this.sortByName === null) {
				localStorage.setItem('ecmclsortbyname', true)
				this.sortByName = true
			} else {
				if (this.sortByName === true) {
					localStorage.setItem('ecmclsortbyname', false)
					this.sortByName = false
				} else if (this.sortByName === false) {
					localStorage.removeItem('ecmclsortbyname')
					this.sortByName = null
				}
			}
			localStorage.removeItem('ecmclsortbydate')
			this.sortByDate = null
		},
		doSortByDate: function () {
			if (this.sortByDate === null) {
				localStorage.setItem('ecmclsortbydate', true)
				this.sortByDate = true
			} else {
				if (this.sortByDate === true) {
					localStorage.setItem('ecmclsortbydate', false)
					this.sortByDate = false
				} else if (this.sortByDate === false) {
					localStorage.removeItem('ecmclsortbydate')
					this.sortByDate = null
				}
			}
			localStorage.removeItem('ecmclsortbyname')
			this.sortByName = null
		},
		doSort: function (values) {
			let result = values

			if (this.sortByName) {
				result = values.sort(this.appService.compareValues('company', 'asc'))
			} else if (this.sortByName === null) {
				result = values.sort(this.appService.compareValues('company', 'asc'))
				result = result.sort(this.appService.compareValues('favorite', 'desc'))
			} else if (this.sortByName === false) {
				result = values.sort(this.appService.compareValues('company', 'desc'))
			}
			if (this.sortByDate !== null) {
				if (this.sortByDate) {
					result = values.sort(this.appService.compareValues('created_at', 'asc'))
				} else {
					result = values.sort(this.appService.compareValues('created_at', 'desc'))
				}
			}
			return result
		},
		onUpdateVisibleItems ({ items }) {
			this.visibleItems = items;
		},
		toggleFavorite: function (company = null) {
			if (company) {
				if (!company.favorite) {
					CustomersManagerService.addToFavorites(company).then(() => {
						company.favorite = true
					})
				} else {
					CustomersManagerService.removeFromFavorites(company).then(() => {
						company.favorite = false
					})
				}
			}
		},
		gotoSettings: function (company) {
			this.appService.goTo({
				name: 'customer',
				params: {
					vendor_id: company.id
				}
			})
		},
		refreshCompaniesList: function () {
			const currentVendorId = this.vendorId
			this.appService
				.loadCompanies()
				.then(() => {
					if (!this.selectedCompany) {
						const companyToSelect = this.companies.find(company => company?.id == currentVendorId)
						if (companyToSelect) {
							this.selectedCompany(companyToSelect)
						} else {
							this.appService.selectDefaultVendor()
						}
					}
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
				})
		},
		updateCounter: function (company) {
			if (company?.id && company?.notifications) {
				this.counters[company.id] = company.notifications
			}
		}
	}
}
</script>

<style scoped>
.v-list__tile {
	/** fix hover transition performance */
	transition: none;
}
</style>
